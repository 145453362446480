import { Helmet } from "react-helmet";
import React from "react";
import styles from "./index.module.scss";

export const About = () => {
  return (
    <div className={styles.About}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Jason Lee Hodges - Artist, Author, Software Engineer</title>
        <link rel="canonical" href={`http://jasonleehodges.io/about`} />
      </Helmet>
      <h1>About</h1>
      <div className={styles.headshot} />
      <div className={styles.desc}>
        Jason Lee Hodges is currently a Senior Software Engineering Manager at a
        high growth unicorn technology startup. He started programming when he
        was 11 years old and currently codes in Python, Java, Scala, Kotlin,
        Typescript, and Rust on a daily basis. He believes Scala is the ultimate
        teaching language and that anyone who is willing to put in the work can
        learn to be a software engineer. You can reach Jason on Twitter
        @jasonleehodges.
      </div>
    </div>
  );
};
