import { faGithub, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react';
import styles from './index.module.scss';

export const Footer: React.FC = () => {
    return (
        <>
            <div className={styles.shape}></div>
            <div className={ styles.Footer }>
                <div className={styles.label}>
                    Jason Lee Hodges on Social
                </div>
                <div>
                    <a href="https://twitter.com/jasonleehodges" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={ faTwitter } className={ styles.icons } />
                    </a>
                    <a href="https://github.com/jasonleehodges" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={ faGithub } className={ styles.icons } />
                    </a>
                    <a href="https://www.linkedin.com/in/jasonleehodges/" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={ faLinkedin } className={ styles.icons } />
                    </a>
                </div>
            </div>
        </>
    );
}