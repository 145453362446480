import 'firebase/auth';

import { Button } from 'antd';
import React from 'react';
import { UploadContent } from '..';
import firebase from 'firebase/app';
import { selectUser } from '../../selectors';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';

export const CMS = () => {
    const currentUser = useSelector(selectUser);

    const handleSignIn = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithRedirect(provider);
    }

    return (
        <div className={styles.CMS}>
            <h1 className={styles.content__heading}>Custom Content Management System</h1>
            {
                currentUser === undefined
                    ? <Button type='primary' shape='round' onClick={handleSignIn}>Please Login with Google</Button>
                    : <UploadContent />
            }
        </div>
    )
}