import { createReducer } from '@reduxjs/toolkit';
import { setAuthenticatedUser } from '../actions';

export interface defaultState {
    authenticatedUser?: Object,
}

export const initState: defaultState = {}

export const defaultReducer = createReducer(initState, (builder) => {
    builder.addCase(setAuthenticatedUser, (state, { payload }) => ({
        ...state,
        authenticatedUser: payload,
    }))
});