import { Button } from "antd";
import { Helmet } from "react-helmet";
import React from "react";
import styles from "./index.module.scss";

export const Book = () => {
  return (
    <div className={styles.Book}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Software Engineering From Scratch by Jason Lee Hodges</title>
        <link
          rel="canonical"
          href={`http://jasonleehodges.io/software-engineering-from-scratch`}
        />
      </Helmet>
      <h1>Software Engineering From Scratch</h1>
      <img
        src="./SEfromScratch-Cover.jpg"
        alt="Software Engineering From Scratch"
        className={styles.book__cover}
      />
      <div className={styles.desc}>
        Are you interested in becoming a software engineer? Perhaps you are
        making a career change or exploring careers for the first time and
        software engineering seems intriguing. If so, there are a myriad of
        educational opportunities that you could investigate. Many of them will
        get you up and programming quickly but fail to give you an inclusive
        preview of the entire subject. Others will provide a thoroughly
        comprehensive deep dive that would be impossible for a beginner to
        follow. Software Engineering from Scratch leverages a flexible and
        scalable language in Scala that delivers the best of both worlds.
      </div>
      <a href="http://bit.ly/JHODGES">
        <Button type="primary" shape="round">
          Order Today!
        </Button>
      </a>
    </div>
  );
};
