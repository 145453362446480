import "firebase/firestore";

import { ContentDocument } from "../models";
import firebase from "firebase/app";
import { firebaseConfig } from "../index";

const initDB = (): [firebase.firestore.Firestore, string] => {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
  const db = firebase.firestore();
  const collectionName = "Content";
  return [db, collectionName];
};

export const uploadContent = async (content: ContentDocument) => {
  const [db, collectionName] = initDB();
  return await db.collection(collectionName).doc(content.slug).set(content);
};

export const listContent = (): Promise<ContentDocument[]> => {
  const [db, collectionName] = initDB();
  return db
    .collection(collectionName)
    .orderBy("createdDate", "desc")
    .limit(3)
    .get()
    .then((querysnapshot) => {
      const data: any[] = [];
      querysnapshot.forEach((doc) => data.push(doc.data()));
      return data as ContentDocument[];
    });
};

export const getDocRefBySlug = async (slug: string) => {
  const [db, collectionName] = initDB();
  return await db.collection(collectionName).doc(slug).get();
};

export const getContentBySlug = async (
  slug: string
): Promise<ContentDocument[]> => {
  const docRef = await getDocRefBySlug(slug);
  return docRef.data() as ContentDocument[];
};

export const getSlugAndProceedingContent = async (
  slug: string
): Promise<ContentDocument[]> => {
  const docRef = await getDocRefBySlug(slug);

  const [db, collectionName] = initDB();
  return db
    .collection(collectionName)
    .orderBy("createdDate", "desc")
    .startAt(docRef)
    .limit(3)
    .get()
    .then((querysnapshot) => {
      const data: any[] = [];
      querysnapshot.forEach((doc) => data.push(doc.data()));
      return data as ContentDocument[];
    });
};
