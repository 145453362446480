import 'firebase/storage';

import { Button, Input, Tag, Upload, message } from 'antd';
import { CloseOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import React, { useCallback, useState } from 'react';

import { ContentDocument } from '../../models/index';
import { RcFile } from 'antd/lib/upload/interface';
import firebase from 'firebase/app';
import styles from './index.module.scss';
import { uploadContent } from '../../services/database';
import { uploadImage } from '../../services/files';

export const UploadContent = () => {
    const [slug, setSlug] = useState<string>('');
    const [tag, setTag] = useState<string>('');
    const [tags, setTags] = useState<string[]>([]);
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [file, setFile] = useState<RcFile>();
    const [loading, setLoading] = useState<boolean>();

    const handleSlug = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        setTitle(e.target.value);
        setSlug(encodeURI(e.target.value.replace(/ /gi,'-')));
    }, [])

    const updateTag = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        setTag(e.target.value)
    }, []);

    const saveTag = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            setTags(t => [...t, tag]);
            setTag('');
        }
    }, [tag]);

    const removeTag = (tag: string) => {
        setTags(tags.filter(t => t !== tag));
    }

    const submit = async () => {
        setLoading(true);
        const createdDate: Date = new Date();
        const snapshot = await uploadImage(file as File);
        const storage = firebase.storage();
        const fileLocation = await storage.ref(snapshot.metadata.fullPath).getDownloadURL();

        const document: ContentDocument = {
            createdDate,
            slug,
            title,
            description,
            fileLocation,
            tags,
        }
        uploadContent(document)
            .then(() => {
                message.success('upload successfully.')
                setTitle('');
                setDescription('');
                setSlug('');
                setTags([]);
                setTag('');
                setFile(undefined);
            })
            .catch(() => message.error('Something went wrong'))
            .finally(() => setLoading(false));
    }

    const handleFileSelect = (file: RcFile) => {
        setFile(file);
        return false;
    }

    const handleFileRemove = () => {
        setFile(undefined);
    }

    return (
        <div className={styles.UploadContent}>
            <h3 className={styles.content__heading}>Upload Content</h3>
            <Upload beforeUpload={handleFileSelect} onRemove={handleFileRemove}>
                <Button icon={<UploadOutlined />}>Click to Upload Content</Button>
            </Upload>
            <Input placeholder={'Title'} value={title} onChange={handleSlug}/>
            <label><strong>{slug}</strong></label>
            <Input.TextArea placeholder={'Description'} value={description} onChange={(e) => setDescription(e.target.value)} />
            <Input placeholder={'Tags'} value={tag} onChange={updateTag} onKeyPress={saveTag} />
            <div className={styles.tags}>{tags.map(t => <Tag color='blue' key={t}>{t}  <CloseOutlined onClick={() => removeTag(t)} /></Tag>)}</div>
            <Button type='primary' shape='round' onClick={submit} disabled={loading}>Submit { loading ? <LoadingOutlined /> : null}</Button>
        </div>
    )
}