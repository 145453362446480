import React, { FC } from "react";

import { ContentDocument } from "../../models/index";
import { Helmet } from "react-helmet";
import { Tag } from "antd";
import moment from "moment";
import styles from "./index.module.scss";

type Props = {
  content: ContentDocument;
};

export const Comic: FC<Props> = ({ content }) => {
  return (
    <div className={styles.Comic}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{content.title} - Original Comic by Jason Lee Hodges</title>
        <link
          rel="canonical"
          href={`http://jasonleehodges.io/comic/${content.slug}`}
        />
      </Helmet>
      <h1>{content.title}</h1>
      <section>
        <div className={styles.image__container}>
          <img src={content.fileLocation} alt={content.title} />
        </div>
        <div className={styles.footer}>
          <div>
            {content.tags.map((tag) => (
              <Tag key={tag} color={"blue"}>
                {tag}
              </Tag>
            ))}
          </div>
          <div className={styles.date}>
            {moment(content.createdDate.toDate()).format("MMM Do YYYY")}
          </div>
        </div>
        <h4 className={styles.description}>{content.description}</h4>
      </section>
    </div>
  );
};
