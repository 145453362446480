import { Comic, LoadingComic } from "..";
import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { ArrowRightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { ContentDocument } from "../../models/index";
import { getSlugAndProceedingContent } from "../../services/database";
import styles from "./index.module.scss";

export const ComicSlug = () => {
  const { slug } = useParams<{ slug: string }>();
  const [content, setContent] = useState<ContentDocument[]>();

  useEffect(() => {
    getSlugAndProceedingContent(slug).then((c) => setContent(c));
  }, [slug]);

  if (content === undefined) {
    return <LoadingComic />;
  }

  return (
    <div className={styles.ComicSlug}>
      <Comic content={content[0]} />
      {content?.[1]?.slug !== undefined ? (
        <Link to={`/comics/${content[1].slug}`}>
          <Button type="primary" shape={"round"}>
            Next <ArrowRightOutlined />
          </Button>
        </Link>
      ) : null}
    </div>
  );
};
