import 'firebase/storage';

import firebase from 'firebase/app';

export const uploadImage = async (file: File) => {
    const storage = firebase.storage();
    const storageRoot = storage.ref();
    const ref = storageRoot.child(`images/${file.name}`);
    return await ref.put(file);
};

