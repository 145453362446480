import React from "react";
import styles from "./index.module.scss";

export const Header = () => {
  return (
    <div>
      <div className={styles.Header}>
        <div className={styles.overlay}>
          <img
            src="/jasonleehodges_icon.png"
            alt="jasonleehodges.io"
            className={styles.logo}
          />
          <h1>Jason Lee Hodges</h1>
          <h3>Artist, Author, and Software Engineer</h3>
          <a href="https://medium.com/@jasonleehodges">
            Follow on Medium For Latest Posts!
          </a>
        </div>
      </div>
      <div className={styles.shape}></div>
    </div>
  );
};
