// import './App.less';

import 'antd/dist/antd.less';

import { Body, Footer, Header } from './components';

import React from 'react';
import styles from './App.module.scss';

// import { Counter } from './features/counter/Counter';


function App() {
  return (
    <div className={styles.App}>
      <Header />
      <Body />
      <Footer />
    </div>
  );
}

export default App;
