import { CMS, ComicSlug } from "..";
import { Link, Route, BrowserRouter as Router, Switch } from "react-router-dom";

import { About } from "../about";
import { Book } from "../book";
import { Button } from "antd";
import { MostRecentComic } from "../most-recent-comic";
import React from "react";
import styles from "./index.module.scss";

export const Body = () => {
  return (
    <div className={styles.Body}>
      <Router>
        <div className={styles.Navigation}>
          <Link to="/comics">
            <Button type="link" style={{ color: "#000" }}>
              Comics
            </Button>
          </Link>
          <Link to="/software-engineering-from-scratch">
            <Button type="link" style={{ color: "#000" }}>
              The Book
            </Button>
          </Link>
          <Link to="/about">
            <Button type="link" style={{ color: "#000" }}>
              About Me
            </Button>
          </Link>
        </div>
        <div className={styles.content}>
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/software-engineering-from-scratch">
              <Book />
            </Route>

            <Route path="/comics/:slug">
              <ComicSlug />
            </Route>
            <Route path="/comics">
              <MostRecentComic />
            </Route>
            <Route path="/admin">
              <CMS />
            </Route>
            <Route path="/">
              <MostRecentComic />
            </Route>
          </Switch>
        </div>
      </Router>
    </div>
  );
};
