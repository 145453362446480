import "./index.css";

import * as serviceWorker from "./serviceWorker";

import App from "./App";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import firebase from "firebase/app";
import { setAuthenticatedUser } from "./actions";
import { store } from "./reducers/store";

export const firebaseConfig = {
  apiKey: "AIzaSyDy_-ZbojvnCJjWSeL6Bv5JKq6tXr6TyFs",
  authDomain: "jasonleehodges-io.firebaseapp.com",
  projectId: "jasonleehodges-io",
  storageBucket: "jasonleehodges-io.appspot.com",
  messagingSenderId: "14551695167",
  appId: "1:14551695167:web:19cd2f918bc63d7df31d8a",
  measurementId: "G-TSK06836YJ",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

firebase
  .auth()
  .getRedirectResult()
  .then((result) => {
    const user: firebase.User | null = result.user;
    store.dispatch(setAuthenticatedUser(user?.toJSON()));
  })
  .catch((error) => {
    console.error(error);
  });

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
