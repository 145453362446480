import React from "react";
import { Skeleton } from "antd";
import styles from "./index.module.scss";

export const LoadingComic = () => (
  <div className={styles.LoadingComic}>
    <Skeleton.Input style={{ width: 200 }} active size={"large"} />
    <div className={styles.image__container}>
      <Skeleton.Image style={{ width: 500, height: 400 }} />
    </div>
    <div className={styles.footer}>
      <div>
        <Skeleton.Button active shape={"round"} />
        <Skeleton.Button active shape={"round"} />
      </div>
      <Skeleton.Input style={{ width: 100 }} active size={"default"} />
    </div>
    <Skeleton active paragraph={{ rows: 2 }} />
  </div>
);
