import { Comic, LoadingComic } from "..";
import React, { useEffect, useState } from "react";

import { ArrowRightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { ContentDocument } from "../../models";
import { Link } from "react-router-dom";
import { listContent } from "../../services/database";
import styles from "./index.module.scss";

export const MostRecentComic = () => {
  const [content, setContent] = useState<ContentDocument[]>();
  useEffect(() => {
    listContent().then((c) => setContent(c));
  }, []);

  if (content === undefined) {
    return <LoadingComic />;
  }

  return (
    <div className={styles.MostRecentComic}>
      <Comic content={content[0]} />

      <Link to={`/comics/${content[1].slug}`}>
        <Button type="primary" shape={"round"}>
          Next <ArrowRightOutlined />
        </Button>
      </Link>
    </div>
  );
};
